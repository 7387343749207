import { useEffect, useState } from "react"
import { Image, Text, View } from "react-native"

type StreakProps = {
  completed: [boolean, boolean, boolean, boolean, boolean]
  streak: number
}

export default function Streak(props: StreakProps) {
  const [streak, setStreak] = useState(0)
  const [completed, setCompleted] = useState([
    false,
    false,
    false,
    false,
    false
  ])

  useEffect(() => {
    setStreak(props.streak)
    setCompleted(props.completed.reverse())
  }, [props.completed, props.streak])

  return (
    <View className="flex flex-col items-center gap-3">
      <View className="max-w-[500px] w-full">
        <View className="flex flex-row justify-evenly">
          {completed.map((success, i) => {
            const date = new Date(Date.now() - (4 - i) * 86400000)
            return (
              <View
                className="flex flex-col items-center"
                key={`${i}` + (success ? "1" : "0")}
              >
                <Text className="text-grey">
                  {date.getDate()}/{date.getMonth()}
                </Text>
                <Image
                  source={
                    success
                      ? require("../../assets/daily-success.png")
                      : require("../../assets/daily-miss.png")
                  }
                  className="w-12 h-12"
                />
              </View>
            )
          })}
        </View>
      </View>
      <View>
        <Text className="italic text-lg text-center">
          {streak > 1
            ? `You're on a ${streak} day streak, keep it up!`
            : streak == 1
            ? "You've just started your streak, good luck!"
            : "You haven't got a streak yet, let's start one!"}
        </Text>
      </View>
    </View>
  )
}
