import React from "react"
import { SafeAreaView, ScrollView } from "react-native"

type ScrollableViewProps = {
  children?: React.ReactNode
}

export default function ScrollableView(props: ScrollableViewProps) {
  return (
    <SafeAreaView className="flex-1">
      <ScrollView>{props.children}</ScrollView>
    </SafeAreaView>
  )
}
