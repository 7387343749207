import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AppStackParamList } from "~/navigation/AppStack"
import { useEffect, useState } from "react"
import { Pressable, Text, View } from "react-native"
import Br from "~/components/layout/Br"
import ScrollableView from "~/components/layout/ScrollableView"
import useApiContext from "~/hooks/useApiContext"
import useIdentityContext from "~/hooks/useIdentityContext"
import { Token } from "@semifinals/token"
import { handleHttpErrors } from "~/utils/handleErrors"
import InputError from "~/components/InputError"
import PendingButton from "~/components/control/PendingButton"
import useUserContext from "~/hooks/useUserContext"
import Input from "~/components/control/Input"
import ScreenContainer from "~/components/layout/ScreenContainer"

export default function MorningReview({
  route,
  navigation
}: NativeStackScreenProps<AppStackParamList, "MorningReview">) {
  const { reviewAdd } = useApiContext()
  const { accessToken } = useIdentityContext()
  const { user, setLastSubmission } = useUserContext()

  const [alcoholCount, setAlcoholCount] = useState<number | null>(null)
  const [alcoholType, setAlcoholType] = useState<number | null>(null)
  const [smokeAmount, setSmokeAmount] = useState<number | null>(null)
  const [weight, setWeight] = useState<number | null>(null)
  const [diet, setDiet] = useState<number | null>(null)
  const [exerciseRoutine, setExercise] = useState<number | null>(null)

  const [errors, setErrors] = useState({
    H010: false,
    H011: false,
    H012: false,
    H013: false,
    H014: false,
    H015: false,
    HTTP409: false
  })

  useEffect(() => {
    if (errors.HTTP409) setLastSubmission(new Date(0))
  }, [errors])

  async function submitAnswers() {
    await reviewAdd(
      Token.getId(accessToken)!,
      alcoholCount,
      alcoholType,
      smokeAmount,
      weight,
      diet,
      exerciseRoutine
    )
      .then(async review => {
        setLastSubmission(null)
        navigation.replace("MorningReviewAnswer", review)
      })
      .catch(err => setErrors(handleHttpErrors(errors, err)))
  }

  return (
    <ScreenContainer>
      <ScrollableView>
        <View className="p-5">
          <Text className="italic text-xs">
            Please fill in all questions marked with an asterisk as they are
            required.
          </Text>
          <Br small />

          <Input
            type="SEGMENTED"
            required
            title="Approximately how many standard alcoholic drinks did you drink yesterday?"
            errors={[
              [errors.H010, "Please select an option above before submitting."]
            ]}
            value={alcoholCount === null ? -1 : alcoholCount}
            values={["0", "1-3", "4-6", "7-10", "10+"]}
            setValue={value => {
              setAlcoholCount(value)
              if (value === 0) setAlcoholType(null)
            }}
          />
          <Br small />

          {!(alcoholCount === null || alcoholCount === 0) && (
            <>
              <Input
                type="DROPDOWN"
                required
                title="What kind of alcohol did you mainly drink?"
                errors={[
                  [
                    errors.H011,
                    "Please select an option above before submitting."
                  ]
                ]}
                value={alcoholType}
                values={["Wine", "Beer", "Spirits", "Cider", "Other"]}
                setValue={e => setAlcoholType(e)}
                defaultValue={alcoholType}
              />
              <Br />
            </>
          )}

          {user.isSmoker && (
            <>
              <Input
                type="SEGMENTED"
                required
                title="Approximately how much did you smoke yesterday?"
                errors={[
                  [
                    errors.H012,
                    "Please select an option above before submitting."
                  ]
                ]}
                value={smokeAmount === null ? -1 : alcoholCount}
                values={["0", "1-3", "4-6", "7-10", "10+"]}
                setValue={value => setSmokeAmount(value)}
              />
              <Br />
            </>
          )}

          <Input
            type="NUMBER"
            title="If you weighed yourself this morning, what did you weigh?"
            placeholder="Enter your weight in kilograms (kg)"
            errors={[
              [
                errors.H013,
                "Please enter a valid weight in kilograms if you weighted yourself today."
              ]
            ]}
            value={weight}
            setValue={e => setWeight(e)}
          />
          <Br small />

          <Input
            type="SEGMENTED"
            required
            title="How healthy overall would you say the food you ate yesterday was, where 1 is bad and 5 is great?"
            errors={[
              [
                errors.H014,
                "Please select an option from above before submitting."
              ]
            ]}
            value={diet === null ? -1 : diet - 1}
            values={["1", "2", "3", "4", "5"]}
            setValue={value => setDiet(value + 1)}
          />
          <Br small />

          <Input
            type="CUSTOM"
            required
            title="What would best describe how much exercise you did yesterday?"
            errors={[
              [errors.H015, "Please select an option above before submitting."]
            ]}
          >
            <View className="flex flex-col text-center border-2 border-[#ddd] rounded-xl bg-[#eee] overflow-hidden">
              <View className="flex flex-row">
                <Pressable
                  onPress={() => setExercise(0)}
                  className={`flex-1 ${exerciseRoutine === 0 && "bg-[#ddd]"}`}
                >
                  <View className="p-5">
                    <Text>None</Text>
                    <Text>No activity</Text>
                  </View>
                </Pressable>
                <Pressable
                  onPress={() => setExercise(1)}
                  className={`flex-1 ${exerciseRoutine === 1 && "bg-[#ddd]"}`}
                >
                  <View className="p-5">
                    <Text>Light</Text>
                    <Text>A little activity</Text>
                  </View>
                </Pressable>
              </View>
              <View className="flex flex-row">
                <Pressable
                  onPress={() => setExercise(2)}
                  className={`flex-1 ${exerciseRoutine === 2 && "bg-[#ddd]"}`}
                >
                  <View className="p-5">
                    <Text>Moderate</Text>
                    <Text>A fair bit of activity</Text>
                  </View>
                </Pressable>
                <Pressable
                  onPress={() => setExercise(3)}
                  className={`flex-1 ${exerciseRoutine === 3 && "bg-[#ddd]"}`}
                >
                  <View className="p-5">
                    <Text>Intense</Text>
                    <Text>A lot of activity</Text>
                  </View>
                </Pressable>
              </View>
            </View>
          </Input>
          <Br small />

          <View className="p-5 bg-gray-200 rounded">
            <Text>
              Have you got any medication you need to take in the morning? If
              so, now's the time!
            </Text>
          </View>
          <Br />

          <View>
            <PendingButton title="Submit Answers" onPress={submitAnswers} />
            <InputError visible={errors.HTTP409}>
              You have already submitted today's daily review. Please wait until
              tomorrow to submit again.
            </InputError>
          </View>
        </View>
      </ScrollableView>
    </ScreenContainer>
  )
}
