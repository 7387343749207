import { NavigationContainer } from "@react-navigation/native"
import useIdentityContext from "~/hooks/useIdentityContext"
import AppStack from "./AppStack"
import AuthStack from "./AuthStack"

export default function MainNavigation() {
  const { accessToken, accessTokenExpiry } = useIdentityContext()

  return (
    <NavigationContainer>
      {accessToken && accessTokenExpiry > Date.now() ? (
        <AppStack />
      ) : (
        <AuthStack />
      )}
    </NavigationContainer>
  )
}
