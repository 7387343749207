import { createStackNavigator } from "@react-navigation/stack"
import { useEffect } from "react"
import useApiContext from "~/hooks/useApiContext"
import useIdentityContext from "~/hooks/useIdentityContext"
import useLoading from "~/hooks/useLoading"
import ForgotPassword from "~/pages/ForgotPassword"
import ForgotPasswordSent from "~/pages/ForgotPasswordSent"
import Login from "~/pages/Login"
import Register from "~/pages/Register"
import RegisterComplete from "~/pages/RegisterComplete"

export type AuthStackParamList = {
  Register: undefined
  RegisterComplete: {
    email: string
  }
  Login: undefined
  ForgotPassword: {
    email?: string
  }
  ForgotPasswordSent: {
    email: string
  }
}

const { Navigator, Screen } = createStackNavigator<AuthStackParamList>()

export default function AuthStack() {
  const { accessTokenExpiry, refreshToken, setAuth } = useIdentityContext()
  const { refresh } = useApiContext()
  const { loading, setLoading, LoadingView } = useLoading()

  useEffect(() => {
    setLoading(true)

    if (refreshToken && accessTokenExpiry < Date.now())
      refresh(refreshToken)
        .then(async res => {
          await setAuth(res)
          setLoading(false)
        })
        .catch(async err => {
          await setAuth(null)
          setLoading(false)
        })
    else setLoading(false)
  }, [refreshToken])

  if (loading) return <LoadingView />

  return (
    <Navigator screenOptions={{ headerShown: false }}>
      <Screen
        name="Register"
        component={Register}
        options={{ animationEnabled: true }}
      />
      <Screen
        name="RegisterComplete"
        component={RegisterComplete}
        options={{ animationEnabled: true }}
        initialParams={{ email: "user@example.com" }}
      />
      <Screen
        name="Login"
        component={Login}
        options={{ animationEnabled: true }}
      />
      <Screen
        name="ForgotPassword"
        component={ForgotPassword}
        options={{ animationEnabled: true }}
        initialParams={{}}
      />
      <Screen
        name="ForgotPasswordSent"
        component={ForgotPasswordSent}
        options={{ animationEnabled: true }}
        initialParams={{ email: "user@example.com" }}
      />
    </Navigator>
  )
}
