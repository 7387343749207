import { createContext } from "react"
import fetch from "node-fetch"
import useIdentityContext from "~/hooks/useIdentityContext"
import { Review } from "~/types/Review"
import { ClinicalResult } from "~/types/ClinicalResult"
import { User } from "~/types/User"
import { Auth } from "~/types/Auth"
import { StreakInfo } from "~/types/StreakInfo"
///@ts-ignore
import { DEVELOPMENT } from "@env"

export const ApiContext = createContext<{
  register: (email: string, password: string) => Promise<Auth.Result>
  login: (email: string, password: string) => Promise<Auth.Result>
  refresh: (refreshToken: string) => Promise<Auth.Result>
  revoke: (refreshToken: string) => Promise<void>
  requestPasswordReset: (email: string) => Promise<void>
  reviewAdd: (
    id: string,
    alcoholCount: number,
    alcoholType: number | null,
    smokeAmount: number,
    weight: number | null,
    diet: number,
    exerciseRoutine: number
  ) => Promise<Review.Details>
  clinicalResultAdd: (
    id: string,
    egfr: number | null,
    acr: number | null,
    bloodPressureUpper: number | null,
    bloodPressureLower: number | null,
    bloodGlucose: number | null,
    date: Date
  ) => Promise<ClinicalResult.Details>
  clinicalResultGetById: (userId: string) => Promise<ClinicalResult.Details[]>
  clinicalResultGetByType: (
    userId: string,
    type: string
  ) => Promise<ClinicalResult.Details[]>
  basicInfoGet: (id: string) => Promise<User.Details>
  basicInfoAdd: (
    id: string,
    firstName: string,
    lastName: string,
    dateOfBirth: Date,
    sex: number,
    ethnicity: number,
    height: number,
    weight: number,
    hasKidneyDisease: boolean,
    kidneyDiseaseType: number,
    hasFamilyHistoryOfKD: boolean,
    hasDiabetes: boolean,
    hasCardiovascularDisease: boolean,
    cardiovascularDiseaseType: number,
    hasHighBloodPressure: boolean,
    diet: number,
    exerciseRoutine: number,
    isAlcoholic: boolean,
    alcoholAmount: string | null,
    alcoholPreference: number | null,
    isSmoker: boolean
  ) => Promise<User.Details>
  streakInfoGet: (userId: string, offset: number) => Promise<StreakInfo.Details>
}>(null)

type ApiProviderProps = {
  children?: React.ReactNode
}

export default function ApiProvider(props: ApiProviderProps) {
  const { accessToken, refreshToken, setAuth } = useIdentityContext()

  const request = (endpoint: string, data: RequestData) =>
    apiRequest(endpoint, Object.assign(data, { accessToken }))

  return (
    <ApiContext.Provider
      value={{
        register: async (email: string, password: string) =>
          request("auth/register", {
            method: "POST",
            body: {
              email,
              password
            },
            noBody: true
          }),
        login: async (email: string, password: string) =>
          request("auth/login", {
            method: "POST",
            body: {
              email,
              password
            }
          }),
        refresh: async (refreshToken: string) =>
          request("auth/token", {
            method: "POST",
            body: {
              token: refreshToken,
              grant_type: "refresh_token"
            }
          }),
        revoke: async (refreshToken: string) =>
          request("auth/token/revoke", {
            method: "POST",
            body: {
              token: refreshToken,
              token_type_hint: "refresh_token"
            },
            noBody: true
          }),
        requestPasswordReset: async (email: string) =>
          request("auth/request-password-reset", {
            method: "POST",
            body: { email },
            noBody: true
          }),
        reviewAdd: async (
          id: string,
          alcoholCount: number,
          alcoholType: number | null,
          smokeAmount: number,
          weight: number | null,
          diet: number,
          exerciseRoutine: number
        ) =>
          request(`users/${id}/reviews`, {
            method: "POST",
            body: {
              alcoholCount,
              alcoholType,
              smokeAmount,
              weight,
              diet,
              exerciseRoutine
            }
          }),
        clinicalResultAdd: async (
          id: string,
          egfr: number | null,
          acr: number | null,
          bloodPressureUpper: number | null,
          bloodPressureLower: number | null,
          bloodGlucose: number | null,
          date: Date
        ) =>
          request(`users/${id}/clinicalResult`, {
            method: "POST",
            body: {
              egfr,
              acr,
              bloodPressureUpper,
              bloodPressureLower,
              bloodGlucose,
              date: date.toISOString()
            }
          }),
        clinicalResultGetById: async (userId: string) =>
          request(`users/${userId}/clinicalResult`, {
            method: "GET"
          }),
        clinicalResultGetByType: async (userId: string, type: string) =>
          request(`users/${userId}/clinicalResult/${type}`, {
            method: "GET"
          }),
        basicInfoGet: async (id: string) =>
          request(`users/${id}`, {
            method: "GET"
          }),
        basicInfoAdd: async (
          id: string,
          firstName: string,
          lastName: string,
          dateOfBirth: Date,
          sex: number,
          ethnicity: number,
          height: number,
          weight: number,
          hasKidneyDisease: boolean,
          kidneyDiseaseType: number,
          hasFamilyHistoryOfKD: boolean,
          hasDiabetes: boolean,
          hasCardiovascularDisease: boolean,
          cardiovascularDiseaseType: number,
          hasHighBloodPressure: boolean,
          diet: number,
          exerciseRoutine: number,
          isAlcoholic: boolean,
          alcoholAmount: string | null,
          alcoholPreference: number | null,
          isSmoker: boolean
        ) =>
          request(`users/${id}`, {
            method: "PUT",
            body: {
              firstName,
              lastName,
              dateOfBirth: dateOfBirth.toISOString(),
              sex,
              ethnicity,
              height,
              weight,
              hasKidneyDisease,
              kidneyDiseaseType,
              hasFamilyHistoryOfKD,
              hasDiabetes,
              hasCardiovascularDisease,
              cardiovascularDiseaseType,
              hasHighBloodPressure,
              diet,
              exerciseRoutine,
              isAlcoholic,
              alcoholAmount,
              alcoholPreference,
              isSmoker
            }
          }),
        streakInfoGet: async (id: string, offset: number) =>
          request(`users/${id}/reviews/streak?offset=${offset}`, {
            method: "GET"
          })
      }}
    >
      {props.children}
    </ApiContext.Provider>
  )
}

type RequestData = {
  method: string
  body?: object
  headers?: object
  noBody?: boolean
}

async function apiRequest(
  endpoint: string,
  data: RequestData & { accessToken: string }
) {
  let domain = `https://api.${DEVELOPMENT ? "dev." : ""}ckdfree.com`
  if (DEVELOPMENT) domain = "https://ckd-gateway-dev-fa.azurewebsites.net" // <-- temporary, until API DNS issues are resolved

  return fetch(`${domain}/${endpoint}`, {
    method: data.method,
    body: data.body ? JSON.stringify(data.body) : undefined,
    headers: Object.assign(
      {
        Authorization: data.accessToken
          ? `Bearer ${data.accessToken}`
          : undefined,
        "Content-Type": "application/json"
      },
      data.headers
    )
  })
    .then(async res => {
      if (res.status < 300 && res.status >= 200) return res
      else {
        let body: any
        try {
          body = await res.json()
        } catch (err) {}

        throw {
          status: res.status,
          statusText: res.statusText,
          body
        }
      }
    })
    .then(res => {
      if (!data.noBody) return res.json()
    })
    .catch(err => {
      throw err
    })
}
