import { TextInput, TextInputProps } from "react-native"

type NumberInputProps = {
  value: number | null
  onChangeText: (e: number | null) => void
}

export default function NumberInput(
  props: Omit<TextInputProps, "value" | "onChangeText"> & NumberInputProps
) {
  return (
    <TextInput
      {...props}
      value={
        (props.value?.toString() === "NaN" ? "" : props.value?.toString()) || ""
      }
      onChangeText={e => {
        props.onChangeText(parseInt(e.replace(/\D/g, "")))
      }}
      className={`p-5 bg-[#eee] rounded-xl border-2 border-[#ddd] ${props.className}`}
      keyboardType="number-pad"
    />
  )
}
