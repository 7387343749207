import { Text } from "react-native"
import { View } from "react-native"

const facts: string[] = [
  "Processed foods high in sodium can be harmful if you suffer from kidney disease, so try to limit your intake as much as possible."
  // TODO: Add real facts here
]

export default function Fact() {
  const index = Math.floor(Math.random() * facts.length)
  return (
    <View className="p-5 bg-gray-200 rounded">
      <Text className="text-lg italic mb-3">Did you know...</Text>
      <Text>{facts[index]}</Text>
      {/* <Text className="text-sm italic text-right text-gray-400 mt-3">
        Fact #{index + 1}
      </Text> */}
    </View>
  )
}
