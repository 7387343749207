import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AppStackParamList } from "~/navigation/AppStack"
import { View, Text } from "react-native"
import ScrollableView from "~/components/layout/ScrollableView"
import useUserContext from "~/hooks/useUserContext"
import React from "react"
import NavigationButton from "~/components/control/NavigationButton"
import ScreenContainer from "~/components/layout/ScreenContainer"

export default function MorningReviewAnswer({
  route,
  navigation
}: NativeStackScreenProps<AppStackParamList, "MorningReviewAnswer">) {
  const { user } = useUserContext()

  const alcoholCount = ["0", "1-3", "4-6", "7-10", "10+"][
    route.params!.alcoholCount
  ]
  const smokeAmount = ["0", "1-5", "6-10", "11-15", "15+"][
    route.params!.smokeAmount
  ]
  const bmi = Math.round(
    route.params!.weight / Math.pow(user.height / 100.0, 2)
  )
  const bmiName = (() => {
    if (bmi < 18.5) {
      return "underweight"
    } else if (bmi >= 18.5 && bmi < 25) {
      return "healthy"
    } else if (bmi >= 25 && bmi < 30) {
      return "overweight"
    } else {
      return "obese"
    }
  })()

  const hasAnyRiskFactor =
    route.params!.alcoholCount > 1 ||
    route.params!.smokeAmount > 0 ||
    route.params!.diet < 2 ||
    route.params!.exerciseRoutine < 1 ||
    bmi > 25

  return (
    <ScreenContainer>
      <ScrollableView>
        <View className="p-5 flex flex-col justify-between min-h-[100vh]">
          <View>
            {/* Title and subheading */}
            <View className="text-center mb-3 p-5 bg-gray-200 rounded">
              <Text className="text-2xl text-bold">
                {hasAnyRiskFactor
                  ? "You are potentially at risk of developing CKD."
                  : "We cannot determine your risk of developing CKD."}
              </Text>
            </View>

            {hasAnyRiskFactor && (
              <View>
                <Text className="text-lg color-gray-500">
                  Here are some areas that you could improve on further to
                  reduce your risk:
                </Text>

                {/* Risk factors list */}
                <View className="flex flex-col gap-3 m-5">
                  {route.params!.alcoholCount > 1 && (
                    <View>
                      <Text>
                        Reducing the number of drinks will reduce your risk. The{" "}
                        {alcoholCount} you had yesterday is considered a
                        potential risk factor.
                      </Text>
                    </View>
                  )}
                  {route.params!.smokeAmount > 0 && (
                    <View>
                      <Text>
                        You reported {smokeAmount} smokes today. Reducing your
                        smoking will help reduce your risk of developing kidney
                        disease.
                      </Text>
                    </View>
                  )}
                  {bmi > 25 && (
                    <View>
                      <Text>
                        Your current BMI of {bmi} considers you {bmiName}, which
                        is increasing your risk of kidney disease.
                      </Text>
                    </View>
                  )}
                  {route.params!.diet < 2 && (
                    <View>
                      <Text>
                        Improvements to your diet will go a long way. Try to eat
                        a bit healthier tomorrow.
                      </Text>
                    </View>
                  )}
                  {route.params!.exerciseRoutine < 1 && (
                    <View>
                      <Text>
                        Doing some light daily exercise will help you to be more
                        active and stay healthier. In turn this can reduce your
                        risk of kidney disease.
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            )}

            {/* TODO: Show some general information to stay healthy if they aren't considered at risk */}
          </View>

          {/* Controls */}
          <View>
            <NavigationButton
              title="Done"
              onPress={() => navigation.popToTop()}
            />
          </View>
        </View>
      </ScrollableView>
    </ScreenContainer>
  )
}
