import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AppStackParamList } from "~/navigation/AppStack"
import ScrollableView from "~/components/layout/ScrollableView"
import { Text, View } from "react-native"
import Br from "~/components/layout/Br"
import { useState } from "react"
import useIdentityContext from "~/hooks/useIdentityContext"
import useApiContext from "~/hooks/useApiContext"
import { Token } from "@semifinals/token"
import { handleHttpErrors } from "~/utils/handleErrors"
import InputError from "~/components/InputError"
import PendingButton from "~/components/control/PendingButton"
import Input from "~/components/control/Input"
import ScreenContainer from "~/components/layout/ScreenContainer"
import TestResultCalculator, {
  TestResultGrade
} from "~/helpers/TestResultCalculator"

type Results = {
  id: string | null
  egfr: number | null
  acr: number | null
  bloodPressureUpper: number | null
  bloodPressureLower: number | null
  bloodGlucose: number | null
  date: Date | null
}

export default function ClinicalResults({
  route,
  navigation
}: NativeStackScreenProps<AppStackParamList, "ClinicalResults">) {
  const { clinicalResultAdd } = useApiContext()
  const { accessToken } = useIdentityContext()

  const [results, setResults] = useState<Results>({
    id: Token.getId(accessToken)!,
    egfr: null,
    acr: null,
    bloodPressureUpper: null,
    bloodPressureLower: null,
    bloodGlucose: null,
    date: new Date()
  })

  const [errors, setErrors] = useState({
    H000: false,
    H001: false,
    H002: false,
    H003: false,
    H004: false,
    H005: false,
    HTTP500: false
  })

  async function submitAnswers() {
    await clinicalResultAdd(
      results.id,
      results.egfr,
      results.acr,
      results.bloodPressureUpper,
      results.bloodPressureLower,
      results.bloodGlucose,
      results.date
    )
      .then(review => navigation.replace("ClinicalResultsAnswer", review))
      .catch(err => setErrors(handleHttpErrors(errors, err)))
  }

  function getGradeText(grade: TestResultGrade): string {
    if (grade === TestResultGrade.Unknown)
      return "Enter values for both EGFR and ACR to receive a rating."
    if (grade === TestResultGrade.Green) return "Your results are healthy!"
    if (grade === TestResultGrade.Yellow) return "Your results are borderline."
    if (grade === TestResultGrade.Orange) return "Your results put you at risk."
    if (grade === TestResultGrade.Red) return "Your results are dangerous."
  }

  function getGradeStyle(grade: TestResultGrade): string {
    if (grade === TestResultGrade.Unknown) return "bg-gray-200"
    if (grade === TestResultGrade.Green) return "bg-[#2ecc71]"
    if (grade === TestResultGrade.Yellow) return "bg-[#f1c40f]"
    if (grade === TestResultGrade.Orange) return "bg-[#e67e22]"
    if (grade === TestResultGrade.Red) return "bg-[#e74c3c]"
    return ""
  }

  const grade = TestResultCalculator.grade(results.egfr, results.acr)

  return (
    <ScreenContainer>
      <ScrollableView>
        <View className="p-5">
          <View className={`p-5 rounded bg-gray-200`}>
            <Text>
              Use this form to submit results from tests you have taken. You may
              need a medical professional to help with collecting and submitting
              these results. You only need to fill in this form if you are
              instructed to.
            </Text>
          </View>
          <Br />

          <Text className="italic text-xs">
            Please fill in all questions marked with an asterisk as they are
            required.
          </Text>
          <Br small />

          <View>
            {/* EGFR Input */}
            <Input
              type="NUMBER"
              title="Estimated Glomerular Filtration Rate (eGFR)"
              placeholder="Enter eGFR here (ml/min/1.73m²)"
              errors={[
                [
                  errors.H000,
                  "Please enter a valid answer above, or leave blank."
                ]
              ]}
              value={results.egfr}
              setValue={e => setResults(res => ({ ...res, egfr: e }))}
            />
            <Br small />

            {/* ACR Input */}
            <Input
              type="NUMBER"
              title="Urine Albumin-Creatinine Ratio (Urine ACR)"
              placeholder="Enter ACR here (mg/mmol)"
              errors={[
                [
                  errors.H001,
                  "Please enter a valid answer above, or leave blank."
                ]
              ]}
              value={results.acr}
              setValue={e => setResults(res => ({ ...res, acr: e }))}
            />
            <Br small />

            {/* EGFR/ACR Grading Display */}
            <View className={`p-5 rounded ${getGradeStyle(grade)}`}>
              <Text>{getGradeText(grade)}</Text>
            </View>
            <Br />

            {/* Blood Pressure Input */}
            <Input
              type="BLOOD_PRESSURE"
              title="Blood Pressure"
              upper={results.bloodPressureUpper}
              setUpper={e =>
                setResults(res => ({ ...res, bloodPressureUpper: e }))
              }
              lower={results.bloodPressureLower}
              setLower={e =>
                setResults(res => ({ ...res, bloodPressureLower: e }))
              }
              errors={[
                [
                  errors.H002,
                  "Please enter a valid upper value, or leave blank."
                ],
                [
                  errors.H003,
                  "Please enter a valid lower value, or leave blank."
                ]
              ]}
            />
            <Br small />

            {/* Blood Glucose Input */}
            <Input
              type="NUMBER"
              title="Blood Glucose"
              placeholder="Enter blood glucose level here"
              errors={[
                [
                  errors.H003,
                  "Please enter a valid answer above, or leave blank."
                ]
              ]}
              value={results.bloodGlucose}
              setValue={e => setResults(res => ({ ...res, bloodGlucose: e }))}
            />
            <Br small />

            {/* Date Input */}
            <Input
              type="DATE"
              required
              title="Date of Test Completion"
              errors={[[errors.H004, "Please enter a valid date."]]}
              value={results.date}
              setValue={e => setResults(res => ({ ...res, date: e }))}
            />
            <Br />

            {/* Controls */}
            <View>
              <PendingButton title="Submit Answers" onPress={submitAnswers} />
              <InputError visible={errors.H005}>
                Please enter a value for at least one of the questions above.
              </InputError>
              <InputError visible={errors.HTTP500}>
                Something went wrong that hasn't been handled yet.
              </InputError>
            </View>
          </View>
        </View>
      </ScrollableView>
    </ScreenContainer>
  )
}
