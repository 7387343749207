import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useState } from "react"
import { Text, View } from "react-native"
import Input from "~/components/control/Input"
import LinkButton from "~/components/control/LinkButton"
import PendingButton from "~/components/control/PendingButton"
import InputError from "~/components/InputError"
import Br from "~/components/layout/Br"
import ScreenContainer from "~/components/layout/ScreenContainer"
import ScrollableView from "~/components/layout/ScrollableView"
import useApiContext from "~/hooks/useApiContext"
import { AuthStackParamList } from "~/navigation/AuthStack"
import { handleHttpErrors } from "~/utils/handleErrors"

export default function ForgotPassword({
  route,
  navigation
}: NativeStackScreenProps<AuthStackParamList, "ForgotPassword">) {
  const { requestPasswordReset } = useApiContext()

  const [email, setEmail] = useState(route.params!.email || "")
  const [errors, setErrors] = useState({ I6: false, HTTP400: false })

  async function onSubmit() {
    await requestPasswordReset(email)
      .then(res => navigation.navigate("ForgotPasswordSent"))
      .catch(err => setErrors(handleHttpErrors(errors, err)))
  }

  return (
    <ScreenContainer>
      <ScrollableView>
        <View className="p-5 flex flex-col justify-between min-h-[100vh]">
          <View>
            {/* Title */}
            <View className="flex flec-col justify-center items-center my-5">
              <Text className="text-4xl">Account Recovery</Text>
            </View>

            {/* Page description */}
            <View className="mb-10 mt-3">
              <Text>
                Forgotten your password? Submit the email you created your
                account with below and we can send you a recovery email for you
                to reset your password.
              </Text>
            </View>

            {/* Inputs */}
            <View>
              <Input
                type="EMAIL"
                required
                title="Email Address"
                placeholder="Enter your email address here"
                errors={[
                  [
                    errors.I6,
                    "The email you used doesn't seem to be valid. Please try again."
                  ]
                ]}
                value={email}
                setValue={e => setEmail(e)}
              />
              <Br small />
            </View>

            {/* Submit button */}
            <View className="mt-10">
              <PendingButton onPress={onSubmit} title="Send Email" />
              <InputError visible={errors.HTTP400}>
                Please fill in all required fields.
              </InputError>
            </View>
          </View>
          <View>
            {/* Register redirect */}
            <View className="mt-10 flex flex-col items-center">
              <Text>Remember your password?</Text>
              <LinkButton
                onPress={() => navigation.replace("Login")}
                title="Login here"
                padding
              />
            </View>
          </View>
        </View>
      </ScrollableView>
    </ScreenContainer>
  )
}
